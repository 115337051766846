import { SystemProps, Flex, Box } from '@storyofams/react-ui'
import { Image as ToolkitImage } from '@storyofams/storyblok-toolkit'
import { Text, Button, Heading, Accordion, RichText } from '~components'
import {
  SamplePackageTexture as Texture,
  SamplePackageTextureMobile as TextureMobile
} from './svg/SamplePackageTexture'
import styled from 'styled-components'

type QuestionItem = {
  _uid: string;
  question?: string,
  answer?: any;
}

type FaqSectionProps = {
  content: {
    title?: string;
    description?: string;
    background_color?: {
      color?: string,
    };
    background_texture_color?: {
      color?: string,
    };
    texture_color?: {
      color?: string,
    };
    header_font_color?: {
      color?: string,
    };
    texture_singlecolor_bool?: boolean;
    background_color_single?: {
      color?: string;
    };
    questions?: Array<QuestionItem>;
  };
  first?: boolean;
} & SystemProps;

export const FaqFlexWrapper = styled(Flex)`
  @media screen and (min-width: 1600px){
    margin-left: auto;
    margin-right: auto;
  }
`;


export const FaqSection = ({ content }: FaqSectionProps) => {
  return (
    <Flex
      marginTop='0 !important'
      alignItems='center'
      width="100%"
      position={'relative'}
    >
      <Box
        position={'absolute'}
        top={'0'}
        left={'0'}
        width={'100%'}
        height={'100%'}
        overflow={'hidden'}
        display={['none', 'block']}
        backgroundColor={!!content?.texture_singlecolor_bool && content?.background_color_single?.color}
      >
        {!!content?.texture_singlecolor_bool === false &&
          <Texture
            background_texture_color={
              content?.background_texture_color
                ? content?.background_texture_color.color
                : 'auto'
            }
            texture_color={
              content?.texture_color
                ? content?.texture_color.color
                : 'auto'
            }
          />
        }
      </Box>
      <FaqFlexWrapper
        width="100%"
        mx={[2, 10]}
        my={[4, 8]}
        justifyContent="space-between"
        flexDirection={['column', 'row']}
        backgroundColor={
          content?.background_color
            ? content?.background_color.color
            : 'auto'
        }
        zIndex={'1'}
        maxWidth="1440px"
      >
        <Flex
          width={['100%', '35%']} 
          pt={['48px']}
          pb={['0', '48px']}
          px={['0', '34px']}
          flexDirection={'column'}
        >
          {!!content?.title && (
            <Heading
              as="h3"
              variant="sh3"
              fontSize={['32px', '35px']}
              width={'100%'}
              color={!!content?.header_font_color ? content?.header_font_color.color : 'initial' }
              textAlign={['center', 'left']}
            >{content?.title}</Heading>
          )}
          {!!content?.description && (
            <Text
              mt={[2]}
              whiteSpace="pre-line"
              width={'100%'}
              textAlign={['center', 'left']}
            >{content?.description}</Text>
          )}
        </Flex>
        <Flex
          width={['100%', '60%']} 
          pt={[2, '48px']}
          pb={['0', '48px']}
          px={['0', '34px']}
          flexDirection={'column'}
        >
          {content?.questions?.map((question) => (
            <Accordion
              key={question?._uid}
              title={question?.question}
              py={['18px']}
              textProps={{
                fontSize: '22px',
                fontWeight: '400',
                fontFamily: 'Apercu Pro',
              }}
              useBottomDivider={true}
              dividerColor={'#D8D8D9'}
            >
              <RichText
                fontSize={[1.75, 2]}
                lineHeight="high"
                text={question?.answer}
              />
            </Accordion>
          ))}
        </Flex>
      </FaqFlexWrapper>
    </Flex>
  );
};
